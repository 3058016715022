import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import PostList from "../components/PostList";
import { FormattedMessage } from "react-intl";
import Layout from "../components/Layout";
import { FaRegListAlt } from "react-icons/fa";

const chooser = (data, section) => {
  //console.log(section);
  let posts;
  let total;
  switch (section) {
    case "painting-new":
      posts = data.paintingNew.edges.map((p) => p.node);
      total = data.paintingNew.totalCount;
      break;
    case "painting-oldest":
      posts = data.paintingOld.edges.map((p) => p.node);
      total = data.paintingOld.totalCount;
      break;
    case "sculpture-marble":
      posts = data.marble.edges.map((p) => p.node);
      total = data.marble.totalCount;
      break;
    case "sculpture-bronze":
      posts = data.bronze.edges.map((p) => p.node);
      total = data.bronze.totalCount;
      break;
    case "sculpture-wood":
      posts = data.wood.edges.map((p) => p.node);
      total = data.wood.totalCount;
      break;
    case "sculpture-other-mat":
      posts = data.otherMat.edges.map((p) => p.node);
      total = data.otherMat.totalCount;
      break;
    case "performance":
      //posts = data.performance.edges.map(p => p.node);
      break;
    case "interactivity":
      posts = data.interactivity.edges.map((p) => p.node);
      total = data.interactivity.totalCount;
      break;
    case "augmented-reality":
      posts = data.augmentedReality.edges.map((p) => p.node);
      total = data.augmentedReality.totalCount;
      break;
    default:
      return {};
  }
  return [posts, total];
};

const ArtworksIndexTemplate = ({ data, section }) => {
  const posts = chooser(data, section);

  return (
    <section className="section">
      <div className="container content">
        <header className="title is-size-3 has-text-weight-bold is-bold-light">
          <div className="content">
            <span className="tag is-light is-large">
              <FaRegListAlt className="menu-names" />
              <FormattedMessage
                id="articles.nPosts"
                values={{ nPosts: posts[1] }}
              />
            </span>
          </div>
        </header>
        <PostList posts={posts[0]} />
        <footer className="footer">
          <span className="box has-background-success is-light is-medium">
            <FormattedMessage id="articles.end" />
          </span>
        </footer>
      </div>
    </section>
  );
};

ArtworksIndexTemplate.propTypes = {
  posts: PropTypes.object,
  pageContext: PropTypes.object,
};

class ArtworksIndex extends React.Component {
  render() {
    let data;
    if (this.props.data !== null) {
      data = this.props.data;
    }
    const jsonData = data.allArticlesJson.edges[0].node.articles;
    const section = data.markdownRemark.frontmatter.section;
    return (
      <Layout data={data} jsonData={jsonData} location={this.props.location}>
        <ArtworksIndexTemplate data={data} section={section} />
      </Layout>
    );
  }
}

export default ArtworksIndex;

export const pageQuery = graphql`
  query ArtworksAllPage($lang: String!, $id: String!) {
    site {
      siteMetadata {
        languages {
          langs
          defaultLangKey
        }
      }
    }
    allArticlesJson(filter: { title: { eq: "home" } }) {
      edges {
        node {
          articles {
            en
            it
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        id
        title
        slug
        description
        section
      }
    }
    paintingNew: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { section: { eq: "painting-new" }, lang: { eq: $lang } }
        fields: { langKey: { eq: $lang } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            id
            image {
              childImageSharp {
                gatsbyImageData(quality: 84, layout: FULL_WIDTH)
              }
            }
            title
            description
            section
            lang
            date
            slug
          }
          fields {
            langKey
            slug
            tagSlugs {
              tag
              link
            }
          }
          excerpt
        }
      }
    }
    paintingOld: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { section: { eq: "painting-oldest" }, lang: { eq: $lang } }
        fields: { langKey: { eq: $lang } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            id
            image {
              childImageSharp {
                gatsbyImageData(quality: 84, layout: FULL_WIDTH)
              }
            }
            title
            description
            section
            lang
            date
            slug
          }
          fields {
            langKey
            slug
            tagSlugs {
              tag
              link
            }
          }
          excerpt
        }
      }
    }
    marble: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          section: { eq: "sculpture-marble" }
          lang: { eq: $lang }
        }
        fields: { langKey: { eq: $lang } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            id
            image {
              childImageSharp {
                gatsbyImageData(quality: 84, layout: FULL_WIDTH)
              }
            }
            title
            description
            section
            lang
            date
            slug
          }
          fields {
            langKey
            slug
            tagSlugs {
              tag
              link
            }
          }
          excerpt
        }
      }
    }
    bronze: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          section: { eq: "sculpture-bronze" }
          lang: { eq: $lang }
        }
        fields: { langKey: { eq: $lang } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            id
            image {
              childImageSharp {
                gatsbyImageData(quality: 84, layout: FULL_WIDTH)
              }
            }
            title
            description
            section
            lang
            date
            slug
          }
          fields {
            langKey
            slug
            tagSlugs {
              tag
              link
            }
          }
          excerpt
        }
      }
    }
    wood: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { section: { eq: "sculpture-wood" }, lang: { eq: $lang } }
        fields: { langKey: { eq: $lang } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            id
            image {
              childImageSharp {
                gatsbyImageData(quality: 84, layout: FULL_WIDTH)
              }
            }
            title
            description
            section
            lang
            date
            slug
          }
          fields {
            langKey
            slug
            tagSlugs {
              tag
              link
            }
          }
          excerpt
        }
      }
    }
    otherMat: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          section: { eq: "sculpture-other-mat" }
          lang: { eq: $lang }
        }
        fields: { langKey: { eq: $lang } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            id
            image {
              childImageSharp {
                gatsbyImageData(quality: 84, layout: FULL_WIDTH)
              }
            }
            title
            description
            section
            lang
            date
            slug
          }
          fields {
            langKey
            slug
            tagSlugs {
              tag
              link
            }
          }
          excerpt
        }
      }
    }
    interactivity: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { section: { eq: "interactivity" }, lang: { eq: $lang } }
        fields: { langKey: { eq: $lang } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            id
            image {
              childImageSharp {
                gatsbyImageData(quality: 84, layout: FULL_WIDTH)
              }
            }
            title
            description
            section
            lang
            date
            slug
          }
          fields {
            langKey
            slug
            tagSlugs {
              tag
              link
            }
          }
          excerpt
        }
      }
    }
    augmentedReality: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          section: { eq: "augmented-reality" }
          lang: { eq: $lang }
        }
        fields: { langKey: { eq: $lang } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            id
            image {
              childImageSharp {
                gatsbyImageData(quality: 84, layout: FULL_WIDTH)
              }
            }
            title
            description
            section
            lang
            date
            slug
          }
          fields {
            langKey
            slug
            tagSlugs {
              tag
              link
            }
          }
          excerpt
        }
      }
    }
  }
`;
